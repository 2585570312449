<template>
  <NcmForm
    :ncm="ncm ? ncm : getCurrentNcm()"
    @save="updateNcm"
    :type="'edit'"
  />
</template>

<script>
import beforeLeaveRouteModal from '@/mixins/beforeLeaveRouteModal.js'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
export default {
  components: {
    NcmForm: () => import('./NcmForm'),
  },
  mixins: [beforeLeaveRouteModal, checkProfileRoutePermission],
  data: () => ({
    ncm: { type: 'ncm' },
    askToLeave: true,
  }),
  methods: {
    getCurrentNcm() {
      this.$api
        .get(`ncms/${this.$route.params.id}`)
        .then((res) => {
          this.ncm = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    updateNcm() {
      this.askToLeave = false
      const formData = new FormData()
      const ncmKeys = Object.keys(this.ncm)
      const ncmValues = Object.values(this.ncm)

      for (let i = 0; i < ncmKeys.length; i++) {
        if (ncmValues[i] !== null) {
          formData.append(ncmKeys[i], ncmValues[i])
        }
      }

      this.$api
        .post(`ncms/${this.$route.params.id}`, formData)
        .then(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'NCM atualizado com sucesso',
            confirm: () => {
              this.$router.push({ name: 'ncm' })
            },
          })
        })
        .catch(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Ops! Falha ao tentar atualizar este NCM',
            caption: 'Verifique se os campos estão preenchidos corretamente.',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
    },
  },
  created() {
    this.getCurrentNcm()
  },
  beforeRouteLeave(to, from, next) {
    if (this.askToLeave) {
      this.openModal(
        () => {
          next()
        }, // Caso o modal seja confirmado
        () => {
          next(false)
        } // Caso o modal seja negado
      )
    } else {
      next()
    }
  },
}
</script>
